import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
// //获取原型对象上的push函数
// const originalPush = VueRouter.prototype.push
// //修改原型对象中的push方法
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

const routes = [
  {

    path: '/',
    name: 'Home',
    component: Home,
    redirect:'/homePage',
    children:[
      {
        path: '/homePage',
        name: 'HomePage',
        component: () => import(/* webpackChunkName: "about" */ '../views/home/HomePage')
      },
      {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/about/About.vue')
      },
      {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/about/About.vue'),

      },
      {
        path: '/productCenter',
        name: 'ProductCenter',
        component: () => import(/* webpackChunkName: "about" */ '../views/product/ProductCenter'),
      },
      {
        path: '/aboutDetails',
        name: 'AboutDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/product/AboutDetails')
      },
      {
        path: '/caseDisplay',
        name: 'CaseDisplay',
        component: () => import(/* webpackChunkName: "about" */ '../views/home/CaseDisplay'),
      },
      {
        path: '/gangu',
        name: 'Gangu',
        component: () => import(/* webpackChunkName: "about" */ '../components/Gangu')
      },
      {
        path: '/wuwei',
        name: 'Wuwei',
        component: () => import(/* webpackChunkName: "about" */ '../components/Wuwei')
      },
      {
        path: '/gansu',
        name: 'Gansu',
        component: () => import(/* webpackChunkName: "about" */ '../components/Gansu')
      },
      {
        path: '/contactInformation',
        name: 'ContactInformation',
        component: () => import(/* webpackChunkName: "about" */ '../views/home/ContactInformation'),
      },
      {
        path: '/companyNews',
        name: 'CompanyNews',
        component: () => import(/* webpackChunkName: "about" */ '../views/news/CompanyNews'),
      },
    ]
  },

]

//要加上这个，不然路由会一直跳转
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next) => {
  window.scrollTo(0,0);
  next();
})

export default router
