<template>
  <div class="home">
    <div class="boxHeader">
        <div class="head1">
          <div class="headerCenter">
            <div class="centerLeft">
              <div>
                <i class="el-icon-phone"></i>
                (0931)8880824
              </div>
              <div style="margin-left: 20px">
                <i class="el-icon-message"></i>
                lzbrrqkj@qq.com
              </div>
            </div>
            <div class="centerRight">
              <button >立即咨询</button>
            </div>
          </div>
        </div>
        <div class="head2">
          <div class="headerCenter" style="border-bottom: 1px solid rgba(255, 255, 255, 0.5);">
            <img style="width: 40%;height: 100%" src="../assets/image/353bddcad2cb78a838e982d302c29ed6.png" />
            <ul class="headItem">
              <li v-for="(item,index) in headerList" :key="index" @click="goTo(item.href)">
<!--                <a :href="item.href">{{item.title}}</a>-->
                <a >{{item.title}}</a>
              </li>
            </ul>
          </div>
        </div>
    </div>
    <div class="contain">
      <router-view></router-view>
    </div>
    <div class="foot1">
      <div class="foot1a">
        <ul>
          <li v-for="(item, index) in footList" :key="index">
            <h2>{{item.title}}</h2>
            <p>
              <a v-for="items in item.children" :href="items.href">{{items.title}}</a>
            </p>
            <a :href="item.href">
              <img style="padding-top: 25px" src="../assets/image/34.jpg" />
            </a>
          </li>
          <li class="liLast">
            <div class="liBox liBox1">
              <img width="139" height="51" src="../assets/image/35.jpg" />
              <img width="138" height="138" src="../assets/image/449dc769f5becbfa22ddd207a4d1a084.jpg" />
              <h5 style="width:138px;color: #999999;height: 30px;line-height: 30px;font-size: 14px;margin: 0;text-align: center">官方微信</h5>
            </div>
            <div class="liBox">
              <p>
                联系电话：(0931)8880824
                <br>
                销售热线：18919828493
                <br>
                邮箱：<a href="">lzbrrqkj@qq.com</a>
              </p>
            </div>
          </li>
        </ul>
        <div class="clear"></div>
      </div>
    </div>
    <div class="foot2">
      <div class="foot2a">
        <span >版权所有：兰州博燃燃气科技有限公司</span>
        <span style="margin-left: 20px">公安机关备案号：62010202000523</span>
          <span style="margin-left: 20px" ><a href="https://beian.miit.gov.cn/">陇ICP备2020003985号-2</a></span>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'Home',
  components: {},
  data(){
    return{
      headerList:[
        {
          href:'/homePage',
          title:'首页',
          children:null
        },
        {
          href:'/about',
          title:'关于博燃',
          children: [
            {
              href:'',
              title:'关于我们',
            },
            {
              href:'',
              title:'公司资质',
            },
            {
              href:'',
              title:'招聘消息',
            },
          ]
        },
        {
          href:'/productCenter',
          title:'产品中心',
          children: [
            {
              href:'',
              title:'管网级',
            },
            {
              href:'',
              title:'营业级',
            },
            {
              href:'',
              title:'企业级',
            },
          ]
        },
        {
          href:'/companyNews',
          title:'公司新闻',
          children: null
        },
        {
          href:'/caseDisplay',
          title:'案例展示',
          children: null
        },
        {
          href:'/contactInformation',
          title:'联系方式',
          children: null
        },
        // {
        //   href:'',
        //   title:'在线留言',
        //   children: null
        // },
      ],
      footList:[
        {
          href:'',
          title:'关于我们',
          children:[
            {
              href:'',
              title:'关于我们',
            },
            {
              href:'',
              title:'公司资质',
            },
            {
              href:'',
              title:'招聘信息',
            },
          ]
        },
        {
          href:'',
          title:'产品中心',
          children:null
        },
        {
          href:'',
          title:'案例展示',
          children:null
        },
      ]
    }
  },
  methods:{
    goTo(url){
      this.$router.push(url)
    }
  }
}
</script>
<style lang="less" scoped>
.boxHeader{
  position: relative;
  top: 0;
  width: 100%;
  z-index: 3;
  background-color: #fff;
  .headerCenter{
    color: #ffffff;
    font-size: 12px;
    width: 62.5%;
    margin: 0 auto;
    display: flex;
    height: 100%;
    justify-content: space-between;
    .centerLeft{
      display: flex;
      flex-direction: row;
    }
    .centerRight{
      button{
        width: 102px;
        height: 35px;
        background-color: #FF5C23;
        font-size: 14px;
        color: #ffffff;
        border: 0;
      }
    }
  }
  .head1{
    width: 100%;
    height: 52px;
    line-height: 52px;
    background-color: #001541;
  }
  .head2{
    position: absolute;
    z-index:1000;
    top: 52px;
    width: 100%;
    height: 111px;
    line-height: 111px;
    background-color: transparent;
    opacity: .5;

  }
}
.clear{
  clear: both;
}
.headItem{
  display: flex;
  flex-direction: row;
  height: 100%;
  line-height: 100%;
  margin: 0;
  color: #ffffff;
  width: 50%;
  font-size: 14px;
  font-family: "微软雅黑";
}
.headItem>li{
  width: 12.5%;
  a{
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
    line-height: 98px;
    display: block;
    padding: 0;
    width: 95px;
    text-align: center;
  }
}
.contain{
  width: 100%;
  //height: 200px;
  //padding-top: 98px;
  //background-color: pink;
}
.foot1{
  padding: 50px 0;
  font-family: "微软雅黑";
  font-size: 14px;
  background: url("../assets/image/homeFooter.webp");
  //padding: 35px 0;
  .foot1a{
    width: 62.5%;
    margin: 0 auto;
    li{
      text-align: left;
      width: 213px;
      padding-left: 20px;
      float: left;
      border-left: 1px #626367 dashed;
      h2{
        color: #FFF;
        line-height: 35px;
      }
      p{
        padding-top: 15px;
        a{
          cursor: pointer;
          display: block;
          color: #999;
        }
      }
    }
    .liLast{
      display: flex;
      flex-direction: row;
      width: 320px;
      line-height: 30px;
      float: right;
      color: #999;
      //background-color: #fff;
      .liBox{
        width: 176px;
        height: 100%;
        font-size: 14px;
        p{
          padding-top: 60px;
        }
        a{
          display: inline-block;
          cursor: pointer;
        }
      }
      .liBox1{
        width: 144px;
        display: flex;
        flex-direction: column;
      }
    }
  }

}
.foot2{
  background-color: #245085;
  width: 100%;
  height: 59px;
  line-height: 59px;
  .foot2a{
    width: 62.5%;
    margin: 0 auto;
    text-align: left;
    color: white;
    font-size: 14px;
  }
}
</style>
